import React, { useEffect, useRef, useState } from "react";

import "./Navbar.scss";
import air from "../img/logoNav.svg";
import ScrollSpy from "react-scrollspy";
import { useStore } from "../Component/store/store";
import downarrow from "../Static/Image/downarrow.svg";
import xmark from "../img/xmark.svg";
import a1 from "../img/h1.svg";
import a2 from "../img/h2.svg";
import a3 from "../img/h3.svg";
import a4 from "../img/h4.svg";
import a5 from "../img/h5.svg";
import o1 from "../img/h6.svg";
import o2 from "../img/h7.svg";
import o3 from "../img/h8.svg";
import o4 from "../img/h9.svg";
import mobilecross from "../img/mobilecross.svg";
import { useHistory } from "react-router-dom";

function Navbar({ activeSection }) {
  const history=useHistory()
  const [drawerOpen, setdrawerOpen] = useState(false);
  const [visibleKey, setVisibleKey] = useState(false);
  const [tradeshow,settradeshow]=useState(false)
  const [addition,setaddition]=useState(false)
  const [navHover,setnavHover]=useState("")
  // const observers = useRef([]);

  // const onClick = (item, key) => {
  //   setVisibleKey(key);
  // };

  // const observerCallback = async (e, key) => {
  //   if (e.length && e[0].isIntersecting) {
  //     setVisibleKey(key);
  //   }
  // };

  // useEffect(() => {
  //   if (observerRefs.current?.length && observers.current) {
  //     Array.from(Array(10).keys()).forEach((_u, key) => {
  //       observers.current[key] = new IntersectionObserver((e) =>
  //         observerCallback(e, key)
  //       );
  //       if (observerRefs.current[key]) {
  //         observers.current[key].observe(observerRefs.current[key]);
  //       }
  //     });
  //   }
  //   return () =>
  //     observers.current?.forEach((observer) => observer?.current?.disconnect());
  // }, [observerRefs, observers]);

  const { isOpen, setOpen, navOpen, setNavOpen } = useStore();

  const [disp, setdisp] = useState(false);
  const changeNavbarColor = async () => {
    if (window.scrollY > 60 || isOpen) {
      // setnavdata(activenav)
      // await history.push("/about")
      setdisp(true);
    } else {
      // await history.push("/about/WhatWeDo")
      setdisp(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <>
      <div className="mobile-nav-air">
        <div  style={{ display: "flex" }}>
        
        
          <div  className="pronavlogo" style={{ display: "flex" }}>
          <img src={air} />
<div className="linenav">

</div>
          <div className="air"
          onClick={()=>setOpen(!isOpen)}
          >
<span
style={{
  fontWeight:"300"
}}
>By</span> AIR 
{
  isOpen?
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
<path d="M13.4229 8.05371L6.71141 -1.90735e-05L3.24249e-05 8.05371L13.4229 8.05371Z" fill="white"/>
</svg>
:
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
<path d="M0 0L6.71144 8.05373L13.4228 0H0Z" fill="white"/>
</svg>
}
</div>

         
        </div>

          {/* <div className="drop-box">
            <div className="drop-menu-box" onClick={() => setOpen(!isOpen)}>
              <button className="drop-menu">
                BY&nbsp;<span className="drop-menu-head">AIR</span>
              </button>
              <div
                className="drop-img-box"
                style={{ transform: isOpen ? "rotate(180deg)" : "" }}
              >
                <img className="drop-img" src={downarrow} alt="" />
              </div>
            </div>
          </div> */}
        </div>
<div
style={{
  display:"flex",
  alignItems:"center"
}}
>
        <label>Get Started</label>
        {drawerOpen ? (
            <img 
            style={{
              marginLeft:"10px"
            }}
            onClick={() => setdrawerOpen(false)} src={xmark} />
          ) : (
            <img
            style={{
              marginLeft:"10px"
            }}
            onClick={() => setdrawerOpen(true)} src={mobilecross} />
          )}
          </div>
      </div>
      <div
        className="navigation"
        style={{
          height: "4.5rem",
          background: "#16171A",
        }}
      >
        <div  className="pronavlogo" 
        
        
        style={{ display: "flex" }}>
          <img src={air} />
<div className="linenav">

</div>
          <div className="air"
          onClick={()=>setOpen(!isOpen)} 
          >
<span
style={{
  fontWeight:"300"
}}
>By</span> AIR 



{
  isOpen?
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
<path d="M13.4229 8.05371L6.71141 -1.90735e-05L3.24249e-05 8.05371L13.4229 8.05371Z" fill="white"/>
</svg>
:
<svg

xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
<path d="M0 0L6.71144 8.05373L13.4228 0H0Z" fill="white"/>
</svg>
}




</div>

         
        </div>
        <div
          className="left-side-nav"
          onMouseLeave={() => setVisibleKey(false)}
        >
          <ScrollSpy
            className="member-nav"
            items={["n", "Buy Crypto", "Products", "Pricing", "Resources"]}
            offset={-100}
            currentClassName="isCurrent"
          >
            <li>
              <a
                href="n"
                ref={React.createRef()}
                className="member-text-first"
              ></a>
            </li>
            <li 
            // onMouseEnter={() => setVisibleKey(false)}
            onMouseEnter={() => setnavHover("Products")}
            // onClick={()=>history.push("/tellers")}
            >
              <a 
              //ref={React.createRef()} className="member-text"
              // style={{
              //   color:'#3296ca'
              // }}
              >
          Trade
              </a>
   
           
            </li>
            <li
                onClick={()=>history.push("/tellers")}
            >
              <a 
              // onMouseEnter={() => setVisibleKey(true)} ref={React.createRef()} 
              
              className="member-text">
            Tellers
              </a>
              {visibleKey && (
                <div
                  className="navDropdwown"
                  onMouseLeave={() => setVisibleKey(false)}
                >
                  {/* <h1>Our Product Lines</h1> */}
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Exchange</h2>
                    <p>
                    Buy, Sell & Store Crypto In Canada’s Most Secure Wallet
                    </p>
                  </div>
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Pay</h2>
                    <p>Spend Your Crypto With Our Card & Earn Rewards </p>
                  </div>
                  <div
                    className="listDetail"
                    onClick={() => setVisibleKey(false)}
                  >
                    <h2>X3 Remit</h2>
                    <p>Send Money Overseas Instantly For Free</p>
                  </div>
                </div>
              )}
            </li>
            <li
                 onClick={()=>history.push("/cards")}
            >
              <a onMouseEnter={() => setVisibleKey(false)} ref={React.createRef()} className="member-text">
              Spend
              </a>
            </li>

            <li
            onClick={()=>history.push("/academy")}
            >
              <a
          onMouseEnter={() => setVisibleKey(false)}
                ref={React.createRef()}
                className="member-text"
              >
              Learn
              </a>
            </li>
          </ScrollSpy>

          <div className="nav-button-handle">
            <label
              className="nav-invester"
              onClick={() => {
                window.open("https://x3.momey/affiliates", "_blank");
              }}
            >
          Earn As An Affiliate
            </label>
            <label
              className="Affiliates-nav"
              onClick={() => {
                window.open("https://my.x3.money/", "_blank");
              }}
            >
              Get Started
            </label>
          </div>
        </div>
      </div>
      {drawerOpen && (
        <div className="mobileDrawer">
          <ScrollSpy
            className="member-nav"
            items={["Mision", "Apps", "Opportunities", "Influencers"]}
            offset={-100}
            currentClassName="isCurrent"
          >
            <li
             onClick={() => settradeshow(!tradeshow)}
              // onClick={() => setdrawerOpen(false)}

              // style={{
              //   background: "rgba(22, 23, 26, 0.05)",
              //   borderRadius: " 10px",
              // }}

              style={{
                borderTop:"none"
              }}
            >
              <a  ref={React.createRef()} className="member-text">
              Trade
              </a>



{
  tradeshow ?
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
  <path d="M8.69162 7L4.34579 0L-4.76837e-06 7L8.69162 7Z" fill="#18191D"/>
</svg>
:
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>

}

            </li>
            <div
            className="big-box3"
            // onMouseEnter={() => setnavHover("Products")}
            // onMouseLeave={() => setnavHover("")}
            style={{
              display: tradeshow ? "" : "none",
      
            }}
          >

<section className="menus"  
              onClick={()=>window.open("http://otc.x3.money/","_blank")}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={a1} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCDesks</div>
                  <div className="menu-desc">
                  Buy and sell with crypto with fiat.
                  </div>
                </div>
              </section>
              <section className="menus"
                     onClick={()=>window.open("http://pro.x3.money/","_blank")}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={a2} alt="" />
                </div>
                <div>
                  <div className="menu-title">Terminals</div>
                  <div className="menu-desc">
                  Trade crypto with advanced tools.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenSwap</div>
                  <div className="menu-desc">
                  Easily swap between crypto assets.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a5} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenOptions</div>
                  <div className="menu-desc">
                  Buy and sell futures for digital assets.
                  </div>
                </div>
              </section>

            </div>


            <li >


              <a  className="member-text">
        Tellers

              </a>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>
            </li>


            <li >


<a  className="member-text">
Spend

</a>
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>
</li>


<li >


<a  className="member-text">
Learn

</a>
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>
</li>



<li >


<a  className="member-text">
Earn As An Affiliate

</a>
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>
</li>


<li 
onClick={()=>{
  settradeshow(false);
  setaddition(!addition)
}}
>


<a  className="member-text">
Additional Services

</a>
<svg xmlns="http://www.w3.org/2000/svg" width="9" height="7" viewBox="0 0 9 7" fill="none">
<path d="M0 0L4.34583 7L8.69162 0H0Z" fill="#18191D"/>
</svg>
</li>
<div
            className="big-box3"
            // onMouseEnter={() => setnavHover("Products")}
            // onMouseLeave={() => setnavHover("")}
            style={{
              display: addition ? "" : "none",
      
            }}
          >

<section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o1} alt="" />
                </div>
                <div>
                  <div className="menu-title">Safe Storage</div>
                  <div className="menu-desc">
                  Self custodialized web3 wallet.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o2} alt="" />
                </div>
                <div>
                  <div className="menu-title">CryptocurrencyShield</div>
                  <div className="menu-desc">
                  Insure your crypto transactions.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o3} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCBots</div>
                  <div className="menu-desc">
                  Automate your crypto purchases.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TradeRouting</div>
                  <div className="menu-desc">
                  Trade on multiple exchanges.
                  </div>
                </div>
              </section>

            </div>



            {/* <li onClick={() => setdrawerOpen(false)}>
              <a
                href="#Opportunities"
                ref={React.createRef()}
                className="member-text"
              >
                Affiliates
              </a>
            </li>
            <li onClick={() => setdrawerOpen(false)}>
              <a
                href="#Influencers"
                ref={React.createRef()}
                className="member-text"
              >
                {" "}
                Resources
              </a>
            </li> */}
          </ScrollSpy>

          <div className="nav-button-handle">
            {/* <label
              className="nav-invester"
              onClick={() => {
                setdrawerOpen(false);
                window.open("https://aiprowallet.com/", "_blank");
              }}
            >
              For Investors
            </label> */}
            <label
              className="Affiliates-nav"
              // onClick={() => {
              //   setdrawerOpen(false);
              //   window.open("https://viral.group/", "_blank");
              // }}
            >
              Get Started
            </label>
          </div>
        </div>
      )}


<div
            className="big-box3"
            onMouseEnter={() => setnavHover("Products")}
            onMouseLeave={() => setnavHover("")}
            style={{
              display: navHover === "Products" ? "" : "none",
              // top: openactions ? "93px" : "80px",
            }}
          >
            <div className="menus-left">
              <div className="menu-title">Trading On Your Terms</div>
              <section className="menus"  
              onClick={()=>window.open("http://otc.x3.money/","_blank")}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={a1} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCDesks</div>
                  <div className="menu-desc">
                  Buy and sell with crypto with fiat.
                  </div>
                </div>
              </section>
              <section className="menus"
                     onClick={()=>window.open("http://pro.x3.money/","_blank")}
              >
                <div className="menu-img-box">
                  <img className="menu-img" src={a2} alt="" />
                </div>
                <div>
                  <div className="menu-title">Terminals</div>
                  <div className="menu-desc">
                  Trade crypto with advanced tools.
                  </div>
                </div>
              </section>
              {/* <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a3} alt="" />
                </div>
                <div>
                  <div className="menu-title">Tellers</div>
                  <div className="menu-desc">
                    Create your own crypto enabled remittence platform.
                  </div>
                </div>
              </section> */}
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenSwap</div>
                  <div className="menu-desc">
                  Easily swap between crypto assets.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={a5} alt="" />
                </div>
                <div>
                  <div className="menu-title">TokenOptions</div>
                  <div className="menu-desc">
                  Buy and sell futures for digital assets.
                  </div>
                </div>
              </section>
            </div>
            <div className="menus-right">
              <div className="menu-title">Additional Services</div>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o1} alt="" />
                </div>
                <div>
                  <div className="menu-title">Safe Storage</div>
                  <div className="menu-desc">
                  Self custodialized web3 wallet.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o2} alt="" />
                </div>
                <div>
                  <div className="menu-title">CryptocurrencyShield</div>
                  <div className="menu-desc">
                  Insure your crypto transactions.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o3} alt="" />
                </div>
                <div>
                  <div className="menu-title">OTCBots</div>
                  <div className="menu-desc">
                  Automate your crypto purchases.
                  </div>
                </div>
              </section>
              <section className="menus">
                <div className="menu-img-box">
                  <img className="menu-img" src={o4} alt="" />
                </div>
                <div>
                  <div className="menu-title">TradeRouting</div>
                  <div className="menu-desc">
                  Trade on multiple exchanges.
                  </div>
                </div>
              </section>
            </div>
          </div>
    </>
  );
}

export default Navbar;
