import React, { useContext } from "react";
import "./App.css";
import Home from "./Component/Homepage";
import Incubation from "./Component/Incubation";
import Sample from "./Component/sample";
import Portfolio from "./Component/Portfolio";
import { Route, Switch, Redirect, useHistory, Router } from "react-router-dom";
import { MainContext } from "./Component/Context";
import CoreBrand from "./Component/CoreBrand";
import Contact from "./Component/Contect";
import LandingPage from "./Page/LandingPage";
import Holiding from "./Page/Holiding";
import Countries from "./Page/Countries";
import HomePage from "./Page/HomePage";
import Privacy from "./Page/Privacy";
function App() {
  const value = useContext(MainContext);
 
  const closeoverlay = (e) => {
    // value.setopencard("translateX(-140%)")
    // value.setpagemask("")
    // value.setclosedisp("none"),
    // value.settherightcard("translatex(-140%)")
  };

  return (
    <>
      <div className="main-wrapper" onMouseDown={closeoverlay}>
        <Switch>
          {/* <Route
          exact
          path="/"
          render={() => {
            return (
              <Redirect to="/about" />
            )
          }}
        /> */}
          <Route exact path="/" render={() => <HomePage />} />
          <Route exact path="/about" render={() => <HomePage />} />
          <Route exact path="/incubation" render={() => <Incubation />} />
          <Route exact path="/contact" render={() => <Contact />} />
          <Route exact path="/holdings" render={() => <Holiding />} />
          <Route exact path="/portfolio" render={() => <Portfolio />} />
          <Route exact path="/countries" render={() => <Countries />} />
          <Route exact path="/privacy" render={() => <Privacy />} />
        </Switch>
      </div>
    </>
  );
}

export default App;
